import { mapState } from 'vuex';
import {
  SectionHeader,
  SearchBox,
  BadgeDropdown,
  ListView,
  SectionHeaderTag,
  SelectAPI as MSelectApi,
  Snackbar,
  Select as MSelect,
} from '@/shared/components'
import UIStatus from '@/shared/mixins/UIStatus'
import { STORE_SET_AGREEMENTS } from '@/clients/clientsConstants'
import {
  downloadCSV,
  downloadExcel,
  TimeManager
} from '@/utils'
import PersistentFilters from '@/shared/mixins/PersistentFilters'

export default {
  components: {
    SectionHeader,
    SearchBox,
    BadgeDropdown,
    MSelectApi,
    ListView,
    SectionHeaderTag,
    Snackbar,
    MSelect
  },
  mixins: [UIStatus, PersistentFilters],
  data() {
    return {
      checkedFees: [],
      agreements: [],
      filtersStorageKey: 'fees.list',
      filtersConfig: {
        agreements: null,
        email: this.$store.state.clients.globalFilters.email
      },
      monthsYears: [
        { id: '', text: this.$t('anyone') },
        { id: TimeManager.getMonthYear(new Date()), text: TimeManager.getMonthYear(new Date()) },
        { id: TimeManager.getMonthYear(TimeManager.addMonths(new Date(), -1)), text: TimeManager.getMonthYear(TimeManager.addMonths(new Date(), -1)) },
        { id: TimeManager.getMonthYear(TimeManager.addMonths(new Date(), -2)), text: TimeManager.getMonthYear(TimeManager.addMonths(new Date(), -2)) },
        { id: TimeManager.getMonthYear(TimeManager.addMonths(new Date(), -3)), text: TimeManager.getMonthYear(TimeManager.addMonths(new Date(), -3)) }
      ],
      fees: [],
      fullFees: [],
      snackBarMessage: null
    }
  },
  computed: mapState({
    globalFilters: state => state.clients.globalFilters,
    downloadActive() { return this.snackBarMessage !== null },
    numCheckedFee() { return this.checkedFees.length }
  }),
  created() {
    this.loadFilters()
    this.fetchFees()
  },
  methods: {
    async fetchFees() {
      this.fees = []
      try {
        this.setLoading()
        const params = this.removeEmptyProperties({
          ...this.filters, ...this.globalFilters
        })
        if (this.filters.monthYear) {
          const monthYear = this.filters.monthYear.split('/');
          const day = this.getMonthDays(monthYear[0], monthYear[1])
          const from_date = ''
          const to_date = ''
          params.from_date = from_date.concat(monthYear[1], '-', monthYear[0], '-', '1')
          params.to_date = to_date.concat(monthYear[1], '-', monthYear[0], '-', day)
          delete params.monthYear
        }
        const results = await this.$http.list('fees', params)
        if (results) {
          const iter = results.feesTotal
          // TODO: Refactor this
          // eslint-disable-next-line guard-for-in
          // eslint-disable-next-line no-restricted-syntax,guard-for-in
          for (const agreement in iter) {
            // eslint-disable-next-line no-restricted-syntax
            for (const code in iter[agreement]) {
              // eslint-disable-next-line no-prototype-builtins
              if (!Object.hasOwnProperty(code)) {
                this.fees.push({ agreement, date: code, ...iter[agreement][code] })
              }
            }
          }
          this.setIdeal()
        }
      } catch (error) {
        // eslint-disable-next-line
      console.log('fees.error', error)
        if (!error.message.isCancelled) this.setError(error.message)
      }
    },
    closeSnackBar() {
      this.snackBarMessage = null
    },
    setAgreements() {
      this.fetchFees()
    },
    setFilter(filter, value) {
      this.filters[filter] = value
      this.saveFilters()
      this.fetchFees()
    },
    async download(type) {
      if (this.checkedFees.length !== 0) {
        const promises = []
        this.fullFees = []
        this.checkedFees.forEach((fee) => {
          this.snackBarMessage = `${this.$t('downloadingFile')}`
          const params = this.removeEmptyProperties({
            ...this.filters, ...this.globalFilters
          })
          delete params.monthYear
          const date = fee.date.split('-')
          const day = this.getMonthDays(date[1], date[0])
          const from_date = ''
          const to_date = ''
          params.from_date = from_date.concat(date[0], '-', date[1], '-', '1')
          params.to_date = to_date.concat(date[0], '-', date[1], '-', day)
          params.agreements = fee.agreement
          promises.push(this.$http.getFullFees(params, false))
        })
        const results = await Promise.all(promises)
        results.forEach((result) => {
          result.results.forEach((item) => {
            this.fullFees.push(item)
          })
        })
        if (type === 'csv') {
          downloadCSV(this.fullFees, 'honorarios.csv', {
            contract_id: 'Cliente',
            invoice__idn: 'Factura',
            contract__customer__vat_id: 'CIF Cliente',
            cups: 'CUPS',
            rate_type: 'Tarifa',
            previous_date: 'Fecha anterior',
            current_date: 'Fecha actual',
            num_days: 'Días',
            power: 'Potencia',
            consumption: 'Consumo',
            commission: 'Comision',
            tax: 'IVA',
            total: 'Total',
            withholding: 'IRPF',
            billing_account: 'Cuenta',
            company: 'Empresa',
            agreement_id: 'Comisionista',
            date: 'Fecha Pago',
            comments: 'Comentarios'
          })
        } else if (type === 'xls') {
          downloadExcel(this.fullFees, 'honorarios.xls', {
            contract_id: 'Cliente',
            invoice__idn: 'Factura',
            contract__customer__vat_id: 'CIF Cliente',
            cups: 'CUPS',
            rate_type: 'Tarifa',
            previous_date: 'Fecha anterior',
            current_date: 'Fecha actual',
            num_days: 'Días',
            power: 'Potencia',
            consumption: 'Consumo',
            commission: 'Comision',
            tax: 'IVA',
            total: 'Total',
            withholding: 'IRPF',
            billing_account: 'Cuenta',
            company: 'Empresa',
            agreement_id: 'Comisionista',
            date: 'Fecha Pago',
            comments: 'Comentarios'
          })
        }
      }
    },
    getDropdownMonthYearMessage() {
      if (this.filters.monthYear) {
        return this.filters.monthYear.toString()
      }
      return `${this.$t('Month')}/${this.$t('Year')}`
    },
    removeFilters() {
      this.cleanFilters()
      this.fees = []
      this.fullFees = []
      this.fetchFees()
    },
    agreementsModifier(response) {
      if (response.count > 0) this.$store.dispatch(STORE_SET_AGREEMENTS, response)
    },
    getMonthDays(month, year) {
      return new Date(year, month, 0).getDate();
    },
    setCheckedFees(fees) {
      this.checkedFees = fees
    },
  }
}
